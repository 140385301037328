@media (max-width: 575.98px) {
    .disabledIcon {
        width: 80px;
        height: 35px;
    }
    
    .icon {
        width: 50x;
        height: 35px
    }
}

.disabledIcon {
    width: 100px;
    height: 80px;
}

.icon {
    width: 65px;
    height: 50px
}