@media only screen and (max-width: 1282px) {
    .login-card-image {
        max-height: 80vh;
    }
}

.login-logo-size {
    height: 25vh;
}

.login-card-image {
    max-height: 85vh;
}
